import TabContainer from "./ui/Tab.vue";
import Button from "./ui/Button.vue";
import Group from "./ui/Group.vue";
import List from "./ui/List.vue";
import Panels from "./ui/Panels.vue";
import Panel from "./ui/Panel.vue";

const Tab = Object.assign({}, TabContainer, {
  Button: Button,
  Group: Group,
  List: List,
  Panels: Panels,
  Panel: Panel,
});

export default Tab;
